@import "../../core/typography.scss";
@import "../../core/mixins";

.tooltip {
  font-size: $font-size-micro !important;
  font-family: $base-font-family !important;
  background-color: var(--primary-color-prune) !important;
  text-align: center;
}

.tooltipPlacementTop {
  @include respond-to("mobile") {
    margin-bottom: 16px !important;
  }
}

.tooltipPlacementBottom {
  margin-top: 16px !important;
}

.arrow::before {
  background-color: var(--primary-color-prune) !important;
}

.clickable {
  cursor: pointer;
}

.hide-desktop {
  @include respond-to("desktop") {
    display: none !important;
  }
}

.hide-nodesktop {
  @include respond-to("nodesktop") {
    display: none !important;
  }
}

.hide-mobile {
  @include respond-to("mobile") {
    display: none !important;
  }
}

.hide-nomobile {
  @include respond-to("nomobile") {
    display: none !important;
  }
}

[class~="MuiTooltip-popper"] {
  z-index: 500 !important;
}
