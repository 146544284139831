@import "./variables";

/* width & height */
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--grey20);
  border-radius: 40px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grey80);
  border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
