@import "../../core/typography.scss";
@import "../../core/mixins";
/* Container of the page */
.container {
  background-color: var(--grey10);
  overflow: auto;
  padding-bottom: 16px;

  @include respond-to("mobile") {
    overflow: hidden;
  }

  &,
  & * {
    font-family: $base-font-family !important;
  }

  &-visible {
    @include respond-to("mobile") {
      height: 200px !important;
    }
  }

  &-card {
    &-root {
      border: unset !important;
      box-shadow: var(--shadow-card);
    }

    &-title {
      color: var(--primary-color-prune);
      font-weight: $font-weight-bold;
    }
  }

  &-page-title {
    min-width: 110px;
    width: 100%;
    color: var(--white) !important;
    text-align: center;
    position: absolute;

    @include respond-to("nomobile") {
      margin-top: -256px;
    }

    @include respond-to("mobile") {
      margin-top: -136px;
    }
  }
  .tab {
    background-color: var(--grey30);
    color: var(--primary-color-dark);
    margin-right: 8px;
    padding: 8px 16px;
    min-height: 40px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .currentTab {
    background-color: var(--white);
    border-left: 1px solid var(--grey30);
    border-right: 1px solid var(--grey30);
    border-bottom: 1px solid var(--white);
    color: var(--primary-color-melon);
  }

  .tabs-container {
    position: relative;

    .tabs-bottom-line {
      border-bottom: 1px solid var(--grey30);
      width: 100%;
    }
  }

  .tab-panel {
    border-left: 1px solid var(--grey);
    border-right: 1px solid var(--grey);
  }

  .tabs-content {
    padding-bottom: 48px;
  }
  .tabs-flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-statements {
      font-weight: $font-weight-medium;
      color: var(--primary-color-prune) !important;
      margin-top: 32px;
      margin-bottom: 0;
      width: 219px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      text-align: center;
      min-width: 1100px;
    }

    &-button-container {
      height: 100%;
      display: flex;

      @include respond-to("nomobile") {
        width: 100%;
        justify-content: center;
        align-items: center;
      }

      @include respond-to("mobile") {
        flex-direction: column;
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &-title-giga {
    padding-left: 56px;
    padding-right: 56px;
    padding-bottom: 64px;
    color: var(--primary-color-prune);
    font-weight: $font-weight-bold;
  }

  &-block {
    position: relative;

    @include respond-to("nomobile") {
      margin-top: -99px;
      margin-left: 16px;
      margin-right: 16px;
    }

    @include respond-to("mobile") {
      margin-top: -37px;
      margin-left: 16px;
      margin-right: 16px;
    }

    &-one {
      padding: 0;
      display: flex;

      @include respond-to("nomobile") {
        justify-content: space-between;
      }

      @include respond-to("mobile") {
        justify-content: center;
      }

      &-content {
        background: white;
        display: flex;
        flex-direction: column;
        height: 200px;
        min-width: 280px;
        box-shadow: var(--shadow-card);

        @include respond-to("nomobile") {
          width: 35vw;
        }

        @include respond-to("mobile") {
          width: 100%;
        }
      }
    }
  }

  &-statements {
    display: flex;
    flex-direction: column;

    margin-left: 56px;
    margin-right: 56px;

    @include respond-to("mobile") {
      margin-left: 24px;
      margin-right: 24px;
    }

    &-content {
      @include respond-to("nomobile") {
        padding-top: 80px !important;
        padding-right: 56px;
      }

      @include respond-to("mobile") {
        padding-top: 80px !important;
        padding-right: 16px;
      }
    }

    &-title {
      font-weight: $font-weight-bold;

      @include respond-to("mobile") {
        width: 100%;
      }
    }

    .grid-row {
      &:nth-child(2n) {
        background-color: var(--grey20);
      }
      &:nth-child(2n + 1) {
        background-color: var(--white);
      }
    }
  }

  &-table {
    &-header {
      background-color: #dbd6db;
      height: 56px;
      font-weight: $font-weight-bold !important;
      color: var(--primary-color-prune);
      font-family: $base-font-family !important;
      font-size: $font-size-deci;
      line-height: $line-height-deci;
    }

    &-cell {
      font-family: $base-font-family !important;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: var(--primary-color-dark);
      // overflow: visible !important;

      &-red {
        color: var(--status-red);
        font-weight: $font-weight-bold;
      }

      &-grey {
        color: var(--status-gray);
        font-weight: $font-weight-bold;
      }

      &-orange {
        color: var(--status-orange);
        font-weight: $font-weight-bold;
      }

      &-green {
        color: var(--status-green);
        font-weight: $font-weight-bold;
      }

      &-black {
        color: var(--status-black);
        font-weight: $font-weight-bold;
      }

      > div {
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
      }
    }
    &-cellWritten {
      &:hover {
        div {
          cursor: default !important;
        }
      }
      &:first-of-type:hover {
        cursor: default !important;
        div {
          color: var(--primary-color-dark) !important;
        }
      }

      & div {
        white-space: nowrap;
      }
    }
    &-context-menu {
      min-width: 317px;
      background-color: var(--primary-color-prune);
      z-index: 100;

      &-item {
        font-size: 14px;
        font-weight: $font-weight-medium;
        color: var(--white) !important;
        background-color: var(--primary-color-prune);

        &:hover {
          background-color: var(--primary-color-melon-dark);
          box-shadow: var(--shadow-button-red);
        }
      }

      &-icon {
        color: var(--white) !important;
        margin-right: 6px;
      }
    }

    &-action-button {
      color: var(--primary-color-prune) !important;
      border-color: var(--primary-color-prune);
      text-transform: none;
      font-size: 14px;
      font-weight: $font-weight-bold;
    }
  }

  &-tabs-header {
    margin: 0px;

    @include respond-to("mobile") {
      margin: 0px;
    }
  }
  @include respond-to("mobile") {
    padding-right: -24px;
  }
}

.no-wrap-cell {
  white-space: nowrap;
}

.download-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 8px;
  // white-space: nowrap;
}

.status-text {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  @include respond-to("desktop") {
    & > div {
      width: calc(100% - 36px);
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  // @include respond-to("nodesktop") {
  //   cursor: pointer;
  // }
}

.hide-desktop {
  @include respond-to("desktop") {
    display: none;
  }
}

.hide-nodesktop {
  @include respond-to("nodesktop") {
    display: none;
  }
}

.withOnClick {
  @include respond-to("nodesktop") {
    display: none;
  }
}

.withoutOnClick {
  @include respond-to("desktop") {
    display: none;
  }
}

/* Modal container */
.modal {
  [role="alertdialog"] {
    position: relative;
    box-sizing: border-box;
    max-width: 663px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: var(--grey80);

    /* stylebreakpoint mobile */
    @media (min-width: 639px) {
      min-width: 663px;
    }
  }

  &__header {
    color: var(--primary-color-prune);
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 700;
    color: var(--primary-color-prune);
  }

  &__close {
    position: absolute;
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;

    // invisible button
    height: min-content;
    top: 24px;
    right: 24px;
    padding: 16px;
  }

  &__content {
    padding: 16px 0px 32px;
    text-align: center;
  }

  // Button
  &__actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;
  }
}
