@import "./core/variables";
@import "./core/mixins";
@import "./core/scrollbar";
@import "./core/typography";

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-Italic.ttf) format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-MediumItalic.ttf) format("truetype");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-Bold.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-BoldItalic.ttf) format("truetype");
  font-style: italic;
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  min-width: 375px;
  // overflow: auto !important;
  max-height: 100vh;

  .pt-8 {
    padding-top: 8px !important;
  }

  .pt-12 {
    padding-bottom: 8px !important;
  }

  .pt-16 {
    padding-top: 16px !important;
  }

  .pt-24 {
    padding-top: 24px !important;
  }

  .pt-32 {
    padding-top: 32px !important;
  }

  .pt-40 {
    padding-top: 40px !important;
  }

  .pt-48 {
    padding-top: 48px !important;
  }

  .pt-56 {
    padding-top: 56px !important;
  }

  .pt-64 {
    padding-top: 64px !important;
  }

  .pt-72 {
    padding-top: 72px !important;
  }

  .pt-76 {
    padding-top: 76px !important;
  }

  .pt-80 {
    padding-top: 80px !important;
  }

  .pt-88 {
    padding-top: 88px !important;
  }

  .pt-92 {
    padding-top: 92px !important;
  }

  .pb-0 {
    padding-bottom: 0px !important;
  }

  .pb-8 {
    padding-bottom: 8px !important;
  }

  .pb-12 {
    padding-bottom: 8px !important;
  }

  .pb-14 {
    padding-bottom: 14px !important;
  }

  .pb-16 {
    padding-bottom: 16px !important;
  }

  .pb-24 {
    padding-bottom: 24px !important;
  }

  .pb-32 {
    padding-bottom: 32px !important;
  }

  .pb-40 {
    padding-bottom: 40px !important;
  }

  .pb-56 {
    padding-bottom: 56px !important;
  }

  .pb-76 {
    padding-bottom: 76px !important;
  }

  .pb-80 {
    padding-bottom: 80px !important;
  }

  .mt-0 {
    margin-top: 0px !important;
  }

  .mt-8 {
    margin-top: 8px !important;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mt-16 {
    margin-top: 16px !important;
  }

  .mt-24 {
    margin-top: 24px !important;
  }

  .mt-32 {
    margin-top: 32px !important;
  }

  .mt-40 {
    margin-top: 40px !important;
  }

  .mt-48 {
    margin-top: 48px !important;
  }

  .mt-56 {
    margin-top: 56px !important;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .mt-64 {
    margin-top: 64px !important;
  }

  .mt-80 {
    margin-top: 80px !important;
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mb-48 {
    margin-bottom: 48px;
  }

  .mb-56 {
    margin-bottom: 56px;
  }

  .mb-80 {
    margin-bottom: 80px;
  }

  .ml-8 {
    margin-left: 8px !important;
  }

  .ml-24 {
    margin-left: 24px !important;
  }

  .pr-8 {
    padding-right: 8px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .pr-24 {
    padding-right: 24px;
  }

  .pr-32 {
    padding-right: 32px;
  }

  .pr-40 {
    padding-right: 40px;
  }

  .ml-32 {
    margin-left: 32px;
  }

  .mr-8 {
    margin-right: 8px;
  }

  .mr-16 {
    margin-right: 16px;
  }

  .mr-24 {
    margin-right: 24px;
  }

  .mr-40 {
    margin-right: 40px;
  }

  .pl-4 {
    padding-left: 4px;
  }

  .pl-8 {
    padding-left: 8px;
  }

  .pl-16 {
    padding-left: 16px !important;
  }

  .pl-24 {
    padding-left: 24px !important;
  }

  .pl-32 {
    padding-left: 32px !important;
  }

  .pl-56 {
    padding-left: 56px !important;
  }

  .justify-content-start {
    justify-content: flex-start !important;
  }

  .hide-desktop {
    @include respond-to("desktop") {
      display: none !important;
    }
  }

  .hide-mobile {
    @include respond-to("mobile") {
      display: none !important;
    }
  }

  .hide-nomobile {
    @include respond-to("nomobile") {
      display: none !important;
    }
  }

  .hide-tablet {
    @include respond-to("tablet") {
      display: none !important;
    }
  }

  /* Colors */
  .text-melon {
    color: var(--primary-color-melon) !important;
  }

  .text-dark {
    color: var(--primary-color-dark) !important;
  }

  .text-prune {
    color: var(--primary-color-prune) !important;
  }

  .text-white {
    color: var(--white) !important;
  }

  .text-prune-light {
    color: var(--primary-color-prune-light) !important;
  }

  .text-white {
    color: var(--white) !important;
  }

  .text-center {
    text-align: center;
  }

  .error {
    text-align: start;
    padding-top: 4px;
    font-family: "DM Sans";
    color: var(--primary-color-melon);
    display: block;
    font-size: 14px;
    margin-top: 4px;
  }

  .text-error {
    color: var(--primary-color-melon) !important;
  }

  div#fallbackLoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    img#fallbackLoader {
      width: 36px;
      height: 36px;
    }
  }

  #tarteaucitronRoot * {
    font-family: $base-font-family !important;
  }

  div#tarteaucitronAlertBig {
    background-color: var(--white) !important;
    max-width: 700px;
    max-height: 100vh;
    border-radius: 2px !important;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;

    button {
      padding: 16px !important;
      margin: 24px 24px 0px 24px !important;
      border-radius: 5px;
      font-weight: bold;
    }

    button:nth-child(2) {
      margin-left: 56px !important;
      background-color: var(--primary-color-melon) !important;

      @include respond-to("mobile") {
        margin-right: 100px !important;
        margin-left: 100px !important;
      }
    }

    button:nth-child(3) {
      margin-right: 56px !important;
      background-color: var(--white);
      border: 2px solid var(--primary-color-prune);
      color: var(--primary-color-prune);

      @include respond-to("mobile") {
        margin-right: 100px !important;
        margin-left: 100px !important;
      }

      .tarteaucitronCross::before {
        color: var(--primary-color-prune) !important;
      }

      &:hover {
        background-color: var(--primary-color-prune);
        color: var(--white);

        .tarteaucitronCross::before {
          color: var(--white) !important;
        }
      }
    }

    button:nth-child(4) {
      color: var(--primary-color-prune) !important;
      margin-left: 100px !important;

      @include respond-to("mobile") {
        margin-right: 100px !important;
        margin-left: 100px !important;
      }
    }

    button:nth-child(5) {
      margin-right: 100px !important;

      @include respond-to("mobile") {
        margin-right: 100px !important;
        margin-left: 100px !important;
        margin-top: 0 !important;
      }
    }
  }

  #tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
    color: var(--primary-color-prune) !important;
    font-weight: bold;
  }

  span#tarteaucitronDisclaimerAlert div {
    max-width: 600px;
    margin: 0 auto 0 auto;
    text-align: justify;
  }

  #tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
    color: var(--primary-color-dark) !important;
  }

  #tarteaucitronRoot span#tarteaucitronDisclaimerAlert {
    @include body-16-base;

    a {
      color: var(--primary-color-prune) !important;
      text-decoration: none;
    }

    h2 {
      text-align: center;
      @include heading-24-mega;
      color: var(--primary-color-prune) !important;
      padding-top: 8px;
      padding-bottom: 24px;
    }
  }

  div#tarteaucitronRoot div#tarteaucitronAlertBig {
    width: 95% !important;
  }

  div#tarteaucitronAlertBig:before {
    content: "Scam*";
    @include heading-48-tera;
    color: var(--primary-color-melon);
  }
}

// Supprimer le logo google de la liste de suggestion
.pac-container::after {
  display: none !important;
}

.cursorPointer {
  cursor: pointer;
}

.capitalize-letter {
  text-transform: capitalize;
}

.decorationUnderline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}
