:root {
  /**
  * @tokens PrimaryColors
  * @presenter Color
  */
  --primary-color-melon: #eb2764;
  --primary-color-melon-dark: #cd134d;
  --primary-color-prune-light: #861650;
  --primary-color-prune: #540352;
  --primary-color-mango-light: #ffb415;
  --primary-color-mango: #f7a501;
  --primary-color-dark: #270126;
  --white: #fff;
  --blue: #0762C8;

  /**
  * @tokens repertories
  * @presenter Color
  */
  --repertories-audiovisual: #EB2764;
  --repertories-sonorous: #FFD400;
  --repertories-stillImage: #980B54;
  --repertories-written: #EB6300;
  --repertories-journalist: #F49A00;
  --repertories-specificRefund: #6C606C;

  /**
  * @tokens statusColors
  * @presenter Color
  */

  --status-gray: #877887;
  --status-yellow: #FFD400;
  --status-orange: #F7A501;
  --status-green: #3DCD7F;
  --status-red: #E10600;
  --status-black: #270126;

  /**
  * @tokens SatelliteColors
  * @presenter Color
  */
  --satellite-color-etoiles-documentaire: #afb8b7;
  --satellite-color-auteurs-co: #000f9f;
  --satellite-color-prix-marguerite: #c61255;
  --satellite-color-prix-albert-londres: #e20673;
  --satellite-color-oeil-or: #eb356d;

  /**
  * @tokens AccentColors
  * @presenter Color
  */
  --accent-color-green: #3DCD7F;

  /**
  * @tokens FonctionalColors
  * @presenter Color
  */
  --fonctional-color-redError: #E10600;

  /**
  * @tokens Shades
  * @presenter Color
  */
  --grey10: #faf9fa;
  --grey20: #f6f4f5;
  --grey30: #dbd6db;
  --grey40: #aba0ab;
  --grey50: #877887;
  --grey60: #7a6c79;
  --grey70: #6c606c;
  --grey80: #5f545f;
  --grey90: #514851;
  --grey100: #443c44;

  /**
  * @tokens Shadows
  * @presenter Shadow
  */
  --shadow-navigation: 0 4px 16px rgb(39 1 38 / 4%); /* #270126 4% */
  --shadow-popup: 0 16px 32px rgb(39 1 38 / 16%); /* #270126 16% */
  --shadow-card: 0 4px 16px rgb(84 3 82 / 8%); /* #540352 4% */
  --shadow-card-hover: 0 8px 24px rgb(39 1 38 / 8%); /* #270126 8% */
  --shadow-button-red: 0 8px 18px rgb(235 39 100 / 16%); /* #EB2764 16% */
  --shadow-container: -1px 1px 13px rgb(104 104 104 / 18%); /* #686868 18% */
}
