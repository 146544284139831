@import "./spacing";

/**
* @tokens Typography Scss
* @presenter FontFamily
*/
$base-font-family: "DM Sans", arial, sans-serif;

/**
* @tokens FontWeight Scss
* @presenter FontWeight
*/
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/**
* @tokens FontSize Body Scss
* @presenter FontSize
*/
$font-size-centi: 10px;
$font-size-micro: 12px;
$font-size-deci: 14px;
$font-size-base: 16px;
$font-size-deca: 18px;

/**
* @tokens FontSize Title Scss
* @presenter FontSize
*/
$font-size-hecto: 20px;
$font-size-kilo: 24px;
$font-size-mega: 32px;
$font-size-giga: 40px;
$font-size-tera: 48px;

/**
* @tokens LineHeight Scss
* @presenter LineHeight
*/
$line-height-centi: 16px;
$line-height-micro: 16px;
$line-height-deci: 20px;
$line-height-base: 24px;
$line-height-deca: 24px;
$line-height-hecto: 24px;
$line-height-kilo: 32px;
$line-height-mega: 40px;
$line-height-giga: 48px;
$line-height-tera: 56px;

/**
* Width of Vertical Drawer
*/
$vertical-drawer-width: 72px;

@mixin body-10-centi {
  font-family: $base-font-family;
  font-size: $font-size-centi;
  line-height: $line-height-centi;
}

@mixin body-12-micro {
  font-family: $base-font-family;
  font-size: $font-size-micro;
  line-height: $line-height-micro;
}

@mixin body-14-deci {
  font-family: $base-font-family;
  font-size: $font-size-deci;
  line-height: $line-height-deci;
}

@mixin body-16-base {
  font-family: $base-font-family;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

@mixin body-18-deca {
  font-family: $base-font-family;
  font-size: $font-size-deca;
  line-height: $line-height-deca;
}

@mixin title {
  font-family: $base-font-family;
  letter-spacing: -0.5px;
}

@mixin title-20-hecto {
  @include title;

  font-size: $font-size-hecto;
  line-height: $line-height-hecto;
}

@mixin title-24-kilo {
  @include title;

  font-size: $font-size-kilo;
  line-height: $line-height-kilo;
}

@mixin heading {
  font-family: $base-font-family;
  letter-spacing: -1px;
  margin-top: 0;
  margin-bottom: 0;
}

@mixin heading-24-mega {
  @include heading;

  font-size: $font-size-mega;
  font-weight: $font-weight-medium;
  line-height: $line-height-mega;
}

@mixin heading-40-giga {
  @include heading;

  font-size: $font-size-giga;
  font-weight: $font-weight-semi-bold;
  line-height: $line-height-giga;
}

@mixin heading-48-tera {
  @include heading;

  font-size: $font-size-tera;
  font-weight: $font-weight-bold;
  line-height: $line-height-tera;
}
